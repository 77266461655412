import Audio from '@uppy/audio'
import '@uppy/audio/dist/style.css'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import Dropbox from '@uppy/dropbox'
import Instagram from '@uppy/instagram'
import Greek from '@uppy/locales/lib/el_GR'
import English from '@uppy/locales/lib/en_US'
import '@uppy/progress-bar/dist/style.css'
import {DashboardModal} from '@uppy/react'
import ScreenCapture from '@uppy/screen-capture'
import '@uppy/screen-capture/dist/style.css'
import StatusBar from '@uppy/status-bar'
import Url from '@uppy/url'
import '@uppy/url/dist/style.css'
import Webcam from '@uppy/webcam'
import '@uppy/webcam/dist/style.css'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Swal from 'sweetalert2'
import {FileManagerServices} from '../../../../services/api_services/FileManagerServices'
import {NotifyError, NotifySuccess} from '../../../Helpers/Toast'
import {ConfigModel} from '../../../modules/auth/models/ConfigModel'

export default function Uploader({
  parentId,
  onUploadSuccess,
  setShowUploader,
  isLiveMonitoringUpload,
}: any) {
  const {t} = useTranslation('translation')
  const config: ConfigModel = useSelector((state: any) => state?.config?.config) as ConfigModel
  const userInfo: any = useSelector((state: any) => state?.user?.userInfo)
  const companion_url = config?.companion_url
  const history = useHistory()
  const uppy: any = useMemo(() => {
    const lang = config?.locale //localStorage.getItem('lang')
    const max_size = userInfo?.data?.praxis?.data?.limits?.copyright?.upload_limits?.max_upload_size //computed(() => store.getters.getMaxSize)
    const max_files =
      userInfo?.data?.praxis?.data?.limits?.copyright?.upload_limits?.max_number_files //computed(() => store.getters.getMaxFiles)
    const remains = userInfo?.data?.praxis?.data?.limits?.copyright?.can?.items?.remains
    return new Uppy({
      onBeforeFileAdded: (file: any) => {
        //BEFORE FILE ADDED IN QUEUE WE NEED TO create random string names so can passed the aws check
        let rand = (Math?.random() + 1).toString(36).substring(7)
        file.original_name = file?.name
        if (file?.source === 'Facebook') {
          uppy.restrictions.allowedFileTypes = null
        }
        console.log(max_files)
        file.meta.source = file?.source
        const extension = file?.original_name?.split('.').pop()
        file.name = rand + '_' + Math.floor(Date.now() / 1000) + '.' + extension
        return file
      },
      onBeforeUpload: (files: any) => {
        // we attach title & subtitle in file's meta so can read them after upload
        const fileCount = Object.keys(files)?.length
        if (remains === 0 || fileCount > remains) {
          Swal.fire({
            title: t("Your current plan doesn't support more file uploads"),
            text: t('Please consider upgrading your plan.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Go to plans',
          }).then((result) => {
            if (result?.value) {
              history.push({
                pathname: '/pages/profile/plans',
              })
            }
          })
          return false
        }
        Object.keys(files).forEach((fileID) => {
          files[fileID].original_title = files[fileID]?.meta.title
          files[fileID].original_subtitle = files[fileID]?.meta.subtitle
          uppy.setFileMeta(fileID, {
            title: '',
            subtitle: '',
            name: files[fileID]?.name,
          })
        })
        return true
      },
      locale: lang === 'en' ? English : Greek,
      // language per user's option
      // logger: Uppy.debugLogger,
      autoProceed: false,
      restrictions: {
        maxFileSize: max_size, // this can be found in users object ..
        maxNumberOfFiles: max_files, // this can be found in users object ..
        minNumberOfFiles: 1,
        allowedFileTypes: config?.extensions,
      },
    })
      .use(Webcam, {mirror: false})
      .use(AwsS3Multipart, {
        // IMPORTANT with awss3 MULTIPART
        limit: 5,
        retryDelays: [0, 1000, 3000, 5000],
        companionUrl: companion_url, // COMPANION URL ..YOU CAN GET THIS from config endpoint https://api.copyrightsworld.com/v1/config
      })
      .use(StatusBar, {
        id: 'StatusBar',
        target: 'body',
        hideAfterFinish: true,
        showProgressDetails: false,
        hideUploadButton: true,
        hideRetryButton: false,
        hidePauseResumeButton: false,
        hideCancelButton: false,
      })
      .use(Url, {companionUrl: companion_url})
      .use(Instagram, {companionUrl: companion_url}) //instagram impl
      .use(Dropbox, {companionUrl: companion_url})
      .use(ScreenCapture, {
        //screencast impl
        displayMediaConstraints: {
          video: {
            width: 1280,
            height: 720,
            frameRate: {
              ideal: 3,
              max: 5,
            },
            cursor: 'motion',
            displaySurface: 'monitor',
          },
        },
        userMediaConstraints: {
          audio: true,
        },
        preferredVideoMimeType: 'video/webm',
      })
      .use(Audio, {
        // showAudioSourceDropdown: true,
      })
      .on('dashboard:modal-closed', () => {
        setShowUploader(false)
      })

      .on('upload-success', (file: any, response: any) => {
        const upload_data = {
          //important values with exactly these keys
          key: file.name,
          url: response.uploadURL,
          name: file.original_name,
          title: file.original_title ? file.original_title : `${file.original_name}`,
          subtitle: file.original_subtitle ? file.original_subtitle : `${file.original_name}`,
          folder_id: parentId,
          mode: isLiveMonitoringUpload,
        }
        FileManagerServices.uploadFile(upload_data)
          .then((res) => {
            NotifySuccess(
              t(
                'Success! We now encrypt and process your submission. It will appear in your file manager momentarily.'
              )
            )
            isLiveMonitoringUpload &&
              NotifySuccess(t('Monitroing turned on succesffuly for that file.'))
            setTimeout(() => {
              onUploadSuccess()
            }, 3000)
          })
          .catch((e) => {
            NotifyError(t('Error occured!'))
          })
      })
  }, [])

  useEffect(() => {
    return () => uppy.close()
  }, [])

  const options = {
    proudlyDisplayPoweredByUppy: false,
    trigger: '#uppy-trigger',
    replaceTargetContent: true,
    showProgressDetails: true,
    closeModalOnClickOutside: false,
    note: t('Filetype restrictions :'),
    metaFields: [
      // WE ARE NEED THIS FOR TITTLE AND SUBTITLE OF SUBMISSION
      {
        id: 'title',
        name: t('Title'),
        placeholder: t('Fill this field with your submission title'),
      },
      {
        id: 'subtitle',
        name: t('Subtitle'),
        placeholder: t('Fill this field with your submission subtitle'),
      },
    ],
  }

  return (
    <>
      <DashboardModal
        uppy={uppy}
        // closeModalOnClickOutside
        open={true}
        // onRequestClose={this.handleClose}
        plugins={[
          'Audio',
          'Webcam',
          'ProgressBar',
          'Url',
          //'Instagram',
          //'Dropbox',
          'ScreenCapture',
          //'GoogleDrive',
        ]}
        {...options}
      />

      <ToastContainer />
    </>
  )
}
