import {lazy, Suspense} from 'react'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import Cookies from 'js-cookie'
import BadgeWrapper from '../pages/badge/BadgeWrapper'
import {AffiliatesCoupon} from '../pages/affiliates/AffiliatesCoupon'

export function PrivateRoutes() {
  const {pathname} = useLocation()
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const BlogPassportWrapper = lazy(() => import('../pages/blog-passport/BlogPassportWrapper'))
  const SocialProtectWrapper = lazy(() => import('../pages/social-protect/SocialProtectWrapper'))
  const SocialDetail = lazy(() => import('../pages/social-protect/SocialDetail'))
  const AffiliatesWrapper = lazy(() => import('../pages/affiliates/AffiliatesWrapper'))
  const FileManagerPageWrapper = lazy(() => import('../pages/file-manager/FileManagerPageWrapper'))
  const LiveMonitoringWrapper = lazy(() => import('../pages/live-monitoring/LiveMonitoringWrapper'))
  const BlogDetail = lazy(() => import('../pages/blog-passport/BlogDetail'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  const getRedirectPath = () => {
    const currentPath = Cookies.get('name')
    if (currentPath) {
      if (currentPath === 'whale') {
        return '/web-whale'
      } else if (currentPath === 'toro') {
        //return '/social-toro'
          return '/dashboard'
      }
    }
    return '/dashboard'
  }

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Redirect from='/:url*(/+)' to={pathname.slice(0, -1)} />
        <Route path='/live-monitoring' component={LiveMonitoringWrapper} />
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/file-manager/' component={FileManagerPageWrapper} />
        <Route path='/web-whale' component={BlogPassportWrapper} />
        <Route path='/web-whale-detail/:id' component={BlogDetail} />
        {/* <Route path='/social-toro' component={SocialProtectWrapper} />
        <Route path='/social-toro-detail/:id' component={SocialDetail} exact /> */}
        <Route path='/affiliates' component={AffiliatesWrapper} />
        <Route path='/affiliates-coupon/:id' component={AffiliatesCoupon} />
        <Route path='/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Route path='/badge/:id' component={BadgeWrapper} />
        <Redirect from='/auth' to={getRedirectPath()} />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
