import React from 'react'
import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import toro from '../../../assets/image/tb_logo_circle.png'
import webWhale from '../../../assets/image/whale_circle.png'
import {useTranslation} from 'react-i18next'

export function MenuInner() {
  const {t} = useTranslation('translation')
  const intl = useIntl()
  const userInfo = useSelector((state: any) => state.user.userInfo)

  const tier = userInfo?.data?.praxis?.data?.tier
  return (
    <>
      <MenuItem title={t('Dashboard')} to='/dashboard' />
      <MenuItem isPro={tier?.copyright?.is_trial} title={t('My Uploads')} to='/file-manager' />
      <MenuItem isPro={tier?.defender?.is_trial} title={t('Monitoring')} to='/live-monitoring' />
      <MenuItem
        title={
          <>
            <span className='ms-2'>{t('MyBlogs')}</span>
          </>
        }
        to='/web-whale'
        isActive={!tier.whale}
      />
      {/* <MenuItem
        title={
          <>
            <span className='ms-2'>{t('MySocials')}</span>
          </>
        }
        to='/social-toro'
        isActive={!tier.toro}
      /> */}
      <MenuItem title={t('Affiliates')} to='/affiliates' />
    </>
  )
}
